<template>
    <div class="pb-4">
        <div class="d-flex flex-column" v-if="this.aae != null">
            <div>
                Invoice :

                <span class="font-weight-bold">
                    {{ (this.aae.invoice_no != null) ? this.aae.invoice_no : "-" }}
                </span>
            </div>
            <div>
                Year :

                <span class="font-weight-bold">
                    {{ (this.aae.year != null) ? this.aae.year : "-" }}
                </span>
            </div>
            <div>
                Year(s) of Subscription :

                <span class="font-weight-bold">
                    {{ (this.aae.year_of_subscription != null) ? this.aae.year_of_subscription : "-" }}
                </span>
            </div>
            <div>
                Price (RM) :
                <span class="font-weight-bold">
                    {{ this.aae.price }}
                </span>
            </div>
            <div>
                Funnel Person :
                <span class="font-weight-bold">
                    {{ this.aae.funnel_person }}
                </span>
            </div>
            <div>
                Invoice Sent Date :

                <span class="font-weight-bold">
                    {{ (this.aae.invoice_sent_date != null) ? convertTimeZone(this.aae.invoice_sent_date) : "-" }}
                </span>
            </div>
            <div>
                Payment Date :

                <span class="font-weight-bold">
                    {{ (this.aae.payment_date != null) ? convertTimeZone(this.aae.payment_date) : "-" }}
                </span>
            </div>
            <div>
                Subscription Start Date :

                <span class="font-weight-bold">
                    {{ (this.aae.subscription_start != null) ? convertTimeZone(this.aae.subscription_start) : "-" }}
                </span>
            </div>
            <div>
                Subscription End Date :

                <span class="font-weight-bold">
                    {{ (this.aae.subscription_end != null) ? convertTimeZone(this.aae.subscription_end) : "-" }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "data",
    ],
    data: () => ({
        aae: null,
    }),
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.aae = this.data;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        }
    }


}
</script>