<template>
    <div class="pb-4">
        <div class="d-flex flex-column" v-if="this.refund != null">
            <div class="">
                Status :
                <span class="font-weight-bold">
                    {{ this.refund.refund_status }}
                </span>
            </div>
            <div>
                Amount (RM) :

                <span class="font-weight-bold">
                    {{ (this.refund.refund_amount != null) ? this.refund.refund_amount : "-" }}
                </span>
            </div>
            <div>
                CN No. :

                <span class="font-weight-bold">
                    {{ (this.refund.cn_no != null) ? this.refund.cn_no : "-" }}
                </span>
            </div>
            <div>
                Refund Sent Date :

                <span class="font-weight-bold">
                    {{ (this.refund.refund_sent_date != null) ? convertTimeZone(this.refund.refund_sent_date) : "-" }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "data",
    ],
    data: () => ({
        refund: null,
    }),
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.refund = this.data;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        }
    }


}
</script>