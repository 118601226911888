<template>
    <div class="pb-4">
        <!-- Hello -->
        <div class="d-flex flex-column" v-if="this.finance!=null">
            <div>
                Financial year end ( FYE ) :
                <span
                    class="font-weight-bold">
                    {{ this.finance.fye }}
                </span>
            </div>
            <div>
                Revenue (RM'000)
                <span
                    class="font-weight-bold">
                    {{ (this.finance.revenue!=null)?this.finance.revenue:"-" }}
                </span>
            </div>
            <div>
               Profit before tax (RM'000) : 
               
               <span class="font-weight-bold">
                    {{ (this.finance.pbt!=null)?this.finance.pbt:"-"}}
                </span>
            </div>
            <div>
               Profit after tax
               
               <span class="font-weight-bold">
                {{ (this.finance.pat!=null)?this.finance.pat:"-" }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default{
props:[
    "data",
],
data:()=>({
    finance:null,
}),
mounted(){
    this.fetch();
},
methods:{
    fetch(){
        this.finance = this.data;
    },
    convertTimeZone(time){
            return this.$moment(time).format("LL");
    },
    convertTimeToYear(time) {
        return this.$moment(time).year();
    }
}
}
</script>