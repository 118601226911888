<template>
    <v-container>
        <v-skeleton-loader
        ref="skeleton"
        type="card-avatar"
        v-if="api.isLoading">
        </v-skeleton-loader>
        <div class="d-flex justify-start" v-if="!api.isLoading  && logs!=null">
            <v-btn
                color="primary"
                class="my-3"
                @click="()=>{
                    this.$router.go(-1);
                }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
        </div>
        <v-card color="secondary" v-if="!api.isLoading && logs!=null">
            <div class="text-h5 pa-5 white--text">
                Logs
            </div>
            <p class="px-5 white--text">
                
            </p>
            <v-list>
                <v-list-item
                v-for="(item,index) in logs"
                :key="index">
                    <v-container>
                        <v-card v-if="item.log_class=='cancelCompanySubscription'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ item.description }}
                                </div>

                            </div>
                        </v-card>


                        <v-card v-if="item.log_class=='createCompanyAAEBatch' || item.log_class==='createSubscriptionHistoryBatch' || item.log_class==='createCompanySalesBatch' || item.log_class==='createCompanyHRDCByBatch' || item.log_class==='createSpecialRemarkBatch' || item.log_class==='createCompanyFunnelBatch'" >
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <ComponentLogsJson
                                    :data="JSON.parse(item.description)"/>

                            </div>
                        </v-card>


                        <v-card v-if="item.log_class==='updateCompanyFullInformation' || item.log_class=='updateClient' || item.log_class=='createUserOnboardingBatch'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6 mb-5">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                    <!-- {{ item.created_at.split(" ")[0] }} {{ item.created_at.split(" ")[1] }} -->
                                </div>
                                
                                <div>
                                    <span class="text-h7 mt-3 font-weight-bold">
                                        Before :
                                    </span>

                                    <ComponentLogsCompanyUpdate :data="JSON.parse(item.description).Old" v-if="item.log_class==='updateCompanyFullInformation'"/>
                                    <ComponentLogsUserUpdate
                                    :data="JSON.parse(item.description).Old" v-if="item.log_class==='updateClient'"/>
                                    <ComponentLogsJson
                                    :data="JSON.parse(item.description).Old" v-if="item.log_class==='createUserOnboardingBatch'"/>
                                </div>
                                <div>
                                    <span class="text-h7 mt-3 font-weight-bold">
                                        After :
                                    </span>
                                    <ComponentLogsCompanyUpdate :data="JSON.parse(item.description).New" v-if="item.log_class==='updateCompanyFullInformation'"/>
                                    <ComponentLogsUserUpdate
                                    :data="JSON.parse(item.description).New" v-if="item.log_class==='updateClient'"/>
                                    
                                    <ComponentLogsJson
                                    :data="JSON.parse(item.description).New" v-if="item.log_class==='createUserOnboardingBatch'"/>
                                </div>
                                <!-- <div class="d-flex justify-start mb-2">
                                    {{ JSON.parse(item.description).Old.name }}
                                </div> -->
                            </div>
                        </v-card>
                        <v-card v-if="item.log_class==='VisionFormRenewal' || item.log_class==='VisionFormPaymentStatus'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                 
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                    <!-- {{ item.created_at.split(" ")[0] }} {{ item.created_at.split(" ")[1] }} -->
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ item.description }}
                                </div>
                            </div>
                        </v-card>
                        <v-card v-if="item.log_class==='removePaymentDetail'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ item.created_at.split(" ")[0] }} {{ item.created_at.split(" ")[1] }}
                                </div>
                                <div class="d-flex justify-start font-weight-bold mb-2">
                                    Credit card detail : {{ item.description }}
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='removeDropDown'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <ComponentLogsDropdownOptions
                                :data="JSON.parse(item.description)"/>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='updateSubscriptionHistory' || item.log_class==='updateCompaniesInvoice' || item.log_class==='updateFinanceSurvey' || item.log_class==='updateDropdownKeys' || item.log_class==='updateRemark'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        Before
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateSubscriptionHistory'">
                                        <ComponentlogsSubscriptionHistory :data="JSON.parse(item.description).Old"/>
                                    </div>
                                    <div
                                        v-if="item.log_class==='updateDropdownKeys'">
                                        <ComponentLogsDropdownOptions 
                                        :data="JSON.parse(item.description).Old"/>
                                    </div>
                                    <div
                                        v-if="item.log_class==='updateCompaniesInvoice'">
                                        <ComponentLogsCompanyInvoice :data="JSON.parse(item.description).Old"/>
                                    </div>
                                    <div
                                        v-if="item.log_class==='updateFinanceSurvey'">
                                        <ComponentLogsCompanyFinance
                                        :data="JSON.parse(item.description).Old"/>
                                    </div>
                                    <div
                                        v-if="item.log_class==='updateRemark'">
                                        <ComponentLogsSpecialRemark
                                        :data="JSON.parse(item.description).Old"/>

                                    </div>
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        After
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateSubscriptionHistory'">
                                        <ComponentlogsSubscriptionHistory :data="JSON.parse(item.description).New"/>
                                    </div>
                                    <div
                                        v-if="item.log_class==='updateDropdownKeys'">
                                        <ComponentLogsDropdownOptions
                                            :data="JSON.parse(item.description).New"/>
                                    </div>
                                    <div
                                        v-if="item.log_class==='updateCompaniesInvoice'">
                                        <ComponentLogsCompanyInvoice :data="JSON.parse(item.description).New"/>
                                    </div>
                                    <div
                                        v-if="item.log_class==='updateFinanceSurvey'">
                                        <ComponentLogsCompanyFinance
                                        :data="JSON.parse(item.description).New"/>
                                    </div> 
                                    <div
                                        v-if="item.log_class==='updateRemark'">
                                        <ComponentLogsSpecialRemark
                                        :data="JSON.parse(item.description).New"/>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='createSubscriptionHisotryByCompany' || item.log_class==='createCompaniesInvoice' ||item.log_class==='addFinanceSurvey'|| item.log_class==='addDropDown'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    v-if="item.log_class==='addDropDown'">
                                    <ComponentLogsDropdownOptions
                                    :data="JSON.parse(item.description)"/>
                                </div>
                                <div
                                    v-if="item.log_class==='createSubscriptionHisotryByCompany'">
                                    <ComponentlogsSubscriptionHistory :data="JSON.parse(item.description)"/>
                                </div>
                                <div
                                    v-if="item.log_class==='createCompaniesInvoice'">
                                <ComponentLogsCompanyInvoice :data="JSON.parse(item.description)"/>
                                </div>
                                <div
                                    v-if="item.log_class==='addFinanceSurvey'">
                                    <ComponentLogsCompanyFinance
                                    :data="JSON.parse(item.description)"/>
                                </div>
                            </div>
                            <!-- <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    {{ item.description }}
                                </div> -->
                        </v-card>
                        <v-card
                            v-if="item.log_class==='storeRemark'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    <ComponentLogsSpecialRemark
                                    :data="JSON.parse(item.description)"/>
                                </div>

                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='updateCompanyRefund'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        Before
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyRefund'">
                                        <ComponentLogsCompanyRefund :data="JSON.parse(item.description).Old"/>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        After
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyRefund'">
                                        <ComponentLogsCompanyRefund :data="JSON.parse(item.description).New"/>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='createCompanyRefundByCompany'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    v-if="item.log_class==='createCompanyRefundByCompany'">
                                    <ComponentLogsCompanyRefund :data="JSON.parse(item.description)"/>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='updateCompanySales'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        Before
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanySales'">
                                        <ComponentLogsCompanySales :data="JSON.parse(item.description).Old"/>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        After
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanySales'">
                                        <ComponentLogsCompanySales :data="JSON.parse(item.description).New"/>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='createCompanySalesByCompany'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    v-if="item.log_class==='createCompanySalesByCompany'">
                                    <ComponentLogsCompanySales :data="JSON.parse(item.description)"/>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='updateCompanyHRDC'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        Before
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyHRDC'">
                                        <ComponentLogsCompanyHRDC :data="JSON.parse(item.description).Old"/>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        After
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyHRDC'">
                                        <ComponentLogsCompanyHRDC :data="JSON.parse(item.description).New"/>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='createCompanyHRDCByCompany'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    v-if="item.log_class==='createCompanyHRDCByCompany'">
                                    <ComponentLogsCompanyHRDC :data="JSON.parse(item.description)"/>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='updateCompanyAAE'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        Before
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyAAE'">
                                        <ComponentLogsCompanyAAE :data="JSON.parse(item.description).Old"/>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        After
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyAAE'">
                                        <ComponentLogsCompanyAAE :data="JSON.parse(item.description).New"/>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='createCompanyAAEByCompany'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    v-if="item.log_class==='createCompanyAAEByCompany'">
                                    <ComponentLogsCompanyAAE :data="JSON.parse(item.description)"/>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='updateCompanyFunnel'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        Before
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyFunnel'">
                                        <ComponentLogsCompanyFunnel :data="JSON.parse(item.description).Old"/>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        class="text-h7 mt-3 font-weight-bold">
                                        After
                                    </span>
                                    <div
                                        v-if="item.log_class==='updateCompanyFunnel'">
                                        <ComponentLogsCompanyFunnel :data="JSON.parse(item.description).New"/>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='createCompanyFunnelByCompany'">
                            <div class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    v-if="item.log_class==='createCompanyFunnelByCompany'">
                                    <ComponentLogsCompanyFunnel :data="JSON.parse(item.description)"/>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='store' || item.log_class==='activateManualSubscription'">
                            <div
                                class="pa-4">
                                <div
                                    class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <span
                                    v-if="item.description!='null'"
                                        >
                                        {{ item.description }}
                                    </span>
                                    <span
                                        v-else>
                                        -
                                    </span>
                                </div>
                            </div>
                        </v-card>
                        <v-card v-if="item.log_class==='App\\{closure}'">
                            <div class="pa-4 ">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div class="d-flex justify-start ">
                                    <span>
                                        {{ item.description }}
                                    </span>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            style="overflow:auto"
                            v-if="item.log_class==='sendXeroInvoice'">
                            <div
                                class="pa-4">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div
                                    class="d-flex justify-start mb-2">
                                    <!-- <ul
                                        v-if="typeof item.description === 'object'">
                                        {{ typeof item.description }}
                                        <div
                                            v-for="(value, index) in item.description"
                                            :key="index">


                                        </div>

                                    </ul> -->
                                    <ul
                                        v-if="typeof item.description !== 'object'">
                                        <div
                                        v-for="(value,index) in (typeof item.description !=='object')?JSON.parse(item.description):item.description"
                                        :key="index">
                                        <li
                                        v-if="['id','invoice_link','client_invoice_link','invoice_no','description','total_amount','amount_due','total','tax_amount','company_name','email','attachment_link','cc_email'].includes(index)">
                                        <span
                                            v-if="index==='invoice_link'">
                                            Original invoice
                                            
                                        </span>
                                        <span
                                            v-else-if="index==='client_invoice_link'">
                                            Modified invoice
                                        </span>
                                        <span
                                            v-else>
                                            {{ index.replace("_"," ") }} :
                                        </span>

                                            <a
                                                target="_blank"
                                                v-if="index==='invoice_link' || index==='client_invoice_link'||index==='attachment_link'"
                                                :href="value">
                                                View invoice
                                            </a>
                                            <span
                                                v-else>
                                                {{ value }}
                                            </span>
                                        </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class === 'updateSchedulerSubscription'">
                            <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div class="">
                                    <!-- {{ item.description }} -->
                                    <div
                                        v-for="(value,index) in JSON.parse(item.description)"
                                        :key="index">
                                        <li>
                                            {{ index }} : {{value}}
                                            <!-- <div
                                                v-for="(item,key) in JSON.parse(value)"
                                                :key="key">
                                                {{ item }}
                                            </div> -->
                                            {{ JSON.parse(value) }}
                                        </li>
                                    </div>
                                </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='deleteSchedulerSubscription'">
                            <div class="pa-4 ">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div class="">
                                    <!-- {{ item.description }} -->
                                    <div
                                        v-for="(value,index) in JSON.parse(item.description)"
                                        :key="index">
                                        <li>
                                            {{ index }} : {{value}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            v-if="item.log_class==='pdfDownloadWebinar'">
                            <div class="pa-4 ">
                                <div class="d-flex justify-start text-h6">
                                    {{ item.title }}
                                </div>
                                <div class="d-flex justify-start mb-2">
                                    {{ convertTimeZone(item.created_at) }}
                                </div>
                                <div class="d-flex justify-start ">
                                   <a
                                    @click="openNewTabPdf(item.description)">
                                     Download certificate 
                                    </a>
                                </div>
                            </div>
                        </v-card>

                    </v-container>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentLogsCompanyUpdate from '../logs/ComponentLogsCompanyUpdate.vue';
import ComponentLogsUserUpdate from '../logs/ComponentLogsUserUpdate.vue';
import ComponentlogsSubscriptionHistory from '../logs/ComponentLogsSubscriptionHistory.vue';
import ComponentLogsCompanyInvoice from '../logs/ComponentLogsCompanyInvoice.vue';
import ComponentLogsCompanyRefund from '../logs/ComponentLogsCompanyRefund.vue';
import ComponentLogsCompanySales from '../logs/ComponentLogsCompanySales.vue';
import ComponentLogsCompanyHRDC from '../logs/ComponentLogsCompanyHRDC.vue';
import ComponentLogsCompanyAAE from '../logs/ComponentLogsCompanyAAE.vue';
import ComponentLogsCompanyFinance from '../logs/ComponentLogsCompanyFinance.vue';
import ComponentLogsCompanyFunnel from '../logs/ComponentLogsCompanyFunnel.vue';
import ComponentLogsDropdownOptions from '../logs/ComponentLogsDropdownOptions.vue';
import ComponentLogsSpecialRemark from '../logs/ComponentLogsSpecialRemark.vue';
import ComponentLogsJson from '../logs/ComponentLogsJson.vue';
export default {
    components:{
    ComponentLogsCompanyUpdate,
    ComponentLogsUserUpdate,
    ComponentlogsSubscriptionHistory,
    ComponentLogsCompanyInvoice,
    ComponentLogsCompanyRefund,
    ComponentLogsCompanySales,
    ComponentLogsCompanyHRDC,
    ComponentLogsCompanyAAE,
    ComponentLogsCompanyFinance,
    ComponentLogsCompanyFunnel,
    ComponentLogsSpecialRemark,
    ComponentLogsJson,
    ComponentLogsDropdownOptions,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    logs:null,
    isShowCompanyUpdateDetail:false,
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {

            if(resp.class=="Log"){
                
                this.logs = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchLog(companyId){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "get";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/log/'+companyId;
            return tempApi;
        },
        fetch()
        {
            let companyId = this.$router.history.current.params.id;
            let fetchLogApi = this.fetchLog(companyId);
            this.$api.fetch(fetchLogApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format("LLL");
        },
        openNewTabPdf(data) {
            let link = document.createElement("a");
            data = data.replace(/\\/g, '');
            data = data.replace(/"/g, '');
            link.href = data;
            link.target="_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>