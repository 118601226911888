<template>
    <div class="pb-4">
        <!-- Hello -->
        <div class="d-flex flex-column" v-if="this.invoice!=null">
            <div>
                Invoice no :
                <span
                    class="font-weight-bold">
                    {{ this.invoice.invoice_no }}
                </span>
            </div>
            <div>
                Client request invoice :
                <span
                    class="font-weight-bold">
                    {{ this.invoice.request_invoice }}
                </span>
            </div>
            <div>
               Send date : 
               
               <span class="font-weight-bold">
                    {{ (this.invoice.send_date!=null)?convertTimeZone(this.invoice.send_date):"-" }}
                </span>
            </div>
            <div>
               Invoice to name : 
               
               <span class="font-weight-bold">
                {{ (this.invoice.requester_name!=null)?this.invoice.requester_name:"-" }}
                </span>
            </div>
            <div>
               Invoice to address : 
               
               <span class="font-weight-bold">
                {{  (this.invoice.requester_address !=null)?this.invoice.requester_address:"-" }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default{
props:[
    "data",
],
data:()=>({
    invoice:null,
}),
mounted(){
    this.fetch();
},
methods:{
    fetch(){
        this.invoice = this.data;
    },
    convertTimeZone(time){
            return this.$moment(time).format("LL");
    },
    convertTimeToYear(time) {
        return this.$moment(time).year();
    }
}
}
</script>