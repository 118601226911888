<template>
    <div class="pb-4">
        <div class="d-flex flex-column" v-if="this.hrdc != null">
            <div>
                HRDC Invoice :

                <span class="font-weight-bold">
                    {{ (this.hrdc.hrdc_invoice != null) ? this.hrdc.hrdc_invoice : "-" }}
                </span>
            </div>
            <div>
                Year :

                <span class="font-weight-bold">
                    {{ (this.hrdc.year != null) ? this.hrdc.year : "-" }}
                </span>
            </div>
            <div>
                Year(s) of Subscription :

                <span class="font-weight-bold">
                    {{ (this.hrdc.year_of_subscription != null) ? this.hrdc.year_of_subscription : "-" }}
                </span>
            </div>
            <div>
                HRDC Invoice Sent Date :

                <span class="font-weight-bold">
                    {{ (this.hrdc.hrdc_invoice_sent_date != null) ? convertTimeZone(this.hrdc.hrdc_invoice_sent_date) : "-" }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "data",
    ],
    data: () => ({
        hrdc: null,
    }),
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.hrdc = this.data;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        }
    }


}
</script>