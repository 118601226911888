<template>
    <div class="pb-4">
        <div v-if="this.users != null" class="my-3">
            <div class="d-flex flex-column" v-for="(item, index) in this.users" :key="index">
                <div>
                    <p class="font-weight-bold text-h7 my-5">
                        User {{ index + 1 }}
                    </p>
                </div>
                <div>
                    Name :
                    <span class="font-weight-bold">

                        {{ item.fname + " " + item.lname }}
                    </span>
                </div>
                <div>
                    Email :
                    <span class="font-weight-bold">

                        {{ item.email }}
                    </span>
                </div>
                <div>
                    Mobile :
                    <span class="font-weight-bold">

                        {{ item.mobile }}
                    </span>
                </div>
                <div>
                    Role :
                    <span class="font-weight-bold">

                        {{ item.role }}
                    </span>
                </div>
                <div>
                    Account Type :
                    <span class="font-weight-bold">
                        {{ item.acc_category }}
                    </span>
                </div>
                <div >
                    Bonus Offer :
                    <span class="font-weight-bold" v-if="item.bonus_offer != null">
                        {{ item.bonus_offer }}
                    </span>
                    <span class="font-weight-bold" v-else>
                        -
                    </span>
                </div>
                <div>
                    Referral Points:
                    <span class="font-weight-bold">
                        {{ item.referral_points }}
                    </span>
                </div>
                <div>
                    Existing Client :
                    <span
                        class="font-weight-bold">
                        {{ (item.existing_client!=null)?item.existing_client:"-" }}
                    </span>
                </div>
                <div>
                    Active 
                    <span
                        class="font-weight-bold">
                        {{ (item.active!=null)?item.active:'-' }}
                    </span>
                </div>
                <div>
                    <div>
                        Customer Service:
                        <span
                            class="font-weight-bold">
                            {{ (item.cs_username!=null)?item.cs_username:'-' }}
                        </span>
                    </div>
                </div>
                <div>
                    Welcome Guide Sent:
                    <span class="font-weight-bold">
                        {{ item.welcome_guide_sent }}
                    </span>
                </div>
                <div>
                    Welcome WA Sent:
                    <span class="font-weight-bold">
                        {{ item.welcome_wa_sent }}
                    </span>
                </div>
                <div class="my-2">
                    Verified :
                    <v-chip color="green " class="white--text" v-if="item.email_verified_at != null">
                        {{ "Verified" }}
                    </v-chip>
                    <span v-else>

                        {{ "Not Verified" }}
                    </span>
                </div>
                <div>
                    Status :
                    <v-chip color="green" class="white--text" v-if="item.status == 1">
                        {{ "Enabled" }}
                    </v-chip>
                    <span v-else>

                        {{ "Disabled" }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "data",
    ],
    data: () => ({
        users: [],
    }),
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.users = this.$_.clone(this.data);
        },
        checkIfCanAccessOnboarding() {
            if (this.$store.getters.getFeatures.onboarding.status == 1 || (this.$store.getters.getFeatures.onboarding.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
        checkIfCanAccessBonusOffer() {
            if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
    }


}
</script>