<template>
    <div class="pb-4">
        <!-- Hello -->
        <div class="d-flex flex-column" v-if="this.dropdown!=null">
            <div
                v-for="(item,index) in Object.entries(this.dropdown)"
                :key="index">
                <div
                    v-if="!['id','created_at','updated_at'].includes(item[0])">
                    <span>
                        {{ item[0] }} :
                    </span>
                    <span
                        class="font-weight-bold">
                        {{ item[1] }}
                    </span>

                </div>
            </div>
            <!-- <div>
                Key :
                <span
                    class="font-weight-bold">
                    {{ this.dropdown.key }}
                </span>
            </div>
            <div>
                Value :
                <span
                    class="font-weight-bold">
                    {{ this.dropdown.value }}
                </span>
            </div>
            <div>
                Description :
                <span
                    class="font-weight-bold">
                    {{ this.dropdown.description }}
                </span>
            </div> -->
            
        </div>
    </div>
</template>

<script>

export default{
props:[
    "data",
],
data:()=>({
    dropdown:null,
}),
mounted(){
    this.fetch();
},
methods:{
    fetch(){
        this.dropdown = this.data;
    },
    convertTimeZone(time){
            return this.$moment(time).format("LL");
    },
    convertTimeToYear(time) {
        return this.$moment(time).year();
    }
}
}
</script>