<template>
    <div class="pb-4">
        <div class="d-flex flex-column" v-if="this.sales != null">
            <!-- <div class="">
                Salesperson :
                <span class="font-weight-bold">
                    {{ this.sales.salesperson }}
                </span>
            </div> -->
            <!-- <div class="">
                Team :
                <span class="font-weight-bold">
                    {{ this.sales.salesperson_team }}
                </span>
            </div> -->
            <div class="">
                Name :
                <span class="font-weight-bold">
                    {{ this.sales.salesperson_name }}
                </span>
            </div>
            <div class="">
                Sales Funnel :
                <span class="font-weight-bold">
                    {{ this.sales.sales_funnel }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "data",
    ],
    data: () => ({
        sales: null,
    }),
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.sales = this.data;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        }
    }


}
</script>