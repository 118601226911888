<template>
    <div class="pb-4">
        <!-- Hello -->
        <div class="d-flex flex-column" v-if="this.specialRemark!=null">
            <div>
                Title :
                <span
                    class="font-weight-bold">
                    {{ this.specialRemark.title }}
                </span>
            </div>
            <div>
                Remark :
                <span
                    class="font-weight-bold">
                    {{ this.specialRemark.remark }}
                </span>
            </div>

        </div>
    </div>
</template>

<script>

export default{
props:[
    "data",
],
data:()=>({
    specialRemark:null,
}),
mounted(){
    this.fetch();
},
methods:{
    fetch(){
        this.specialRemark = this.data;
    },
    convertTimeZone(time){
            return this.$moment(time).format("LL");
    },
    convertTimeToYear(time) {
        return this.$moment(time).year();
    }
}
}
</script>