<template>
        <div class="pb-4">
            <!-- Hello -->
            <div>
                <p class="text-h7 font-weight-bold">
                    Company
                </p>
            </div>
            <div class="d-flex flex-column" v-if="this.company!=null">
                <div class="">
                   Name :
                   <span class="font-weight-bold">
                    {{ this.company.name }}
                    </span>
                </div>
                <div>
                   Referral Code : 
                   
                   <span class="font-weight-bold">
    
                        {{ (this.company.coupon!=null)?this.company.coupon:"-" }}
                    </span>
                </div>
                <div>
                   Address : 
                   <span class="font-weight-bold">
    
                       {{ this.company.address }}
                    </span>
                </div>
                <div>
                   SSM No. : 
                   <span class="font-weight-bold">
    
                       {{ this.company.ssm_no }}
                   </span>
                </div>
                <div>
                   Industry : 
                   <span class="font-weight-bold">
                       
                       {{ this.company.industry }}
                    </span>
                </div>
                <div>
                    Main-Category :
                    <span class="font-weight-bold">
                       
                       {{ (this.company.subcription_type!=null)?this.company.subcription_type:"-" }}
                    </span>
    
                </div>
                <div>
                    Sub-Category : 
                    <span class="font-weight-bold">
                       
                        {{ (this.company.branch!=null)?this.company.branch:"-" }}
                    </span>
                </div>
            </div>
        </div>
</template>

<script>

export default{
    props:[
        "data",
    ],
    data:()=>({
        company:null,
    }),
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            this.company = this.data;
        }
    }

    
}
</script>