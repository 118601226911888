<template>
    <div class="pb-4">
        <!-- Hello -->
        <div class="d-flex flex-column" v-if="this.subscriptionHistory!=null">
            <div class="">
               Status :
               <span class="font-weight-bold">
                {{ this.subscriptionHistory.status }}
                </span>
            </div>
            <div>
               Year : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.year!=null)?this.subscriptionHistory.year:"-" }}
                </span>
            </div>
            <div>
               Payment date : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.payment_date!=null)?convertTimeZone(this.subscriptionHistory.payment_date):"-" }}
                </span>
            </div>
            <div>
               Subscription start date : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.subscription_start_date!=null)?convertTimeZone(this.subscriptionHistory.subscription_start_date):"-" }}
                </span>
            </div>
            <div>
               Subscription end date : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.subscription_end_date!=null)?convertTimeZone(this.subscriptionHistory.subscription_end_date):"-" }}
                </span>
            </div>
            <div>
               Price (RM) : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.price!=null)?this.subscriptionHistory.price:"-" }}
                </span>
            </div>
            <div>
               Offer : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.offer!=null)?this.subscriptionHistory.offer:"-" }}
                </span>
            </div>
            <div>
               PIC : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.pic_salesperson!=null)?this.subscriptionHistory.pic_salesperson:"-" }}
                </span>
            </div>
            <div>
               Commision release date : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.commision!=null)?convertTimeZone(this.subscriptionHistory.commision):"-" }}
                </span>
            </div>
            <div>
               Cancellation reason : 
               
               <span class="font-weight-bold">
                    {{ (this.subscriptionHistory.cancellation_reason!=null)?this.subscriptionHistory.cancellation_reason:"-" }}
                </span>
            </div>


          
            <!-- <div>
               Address : 
               <span class="font-weight-bold">

                   {{ this.company.address }}
                </span>
            </div>
            <div>
               SSM No. : 
               <span class="font-weight-bold">

                   {{ this.company.ssm_no }}
               </span>
            </div>
            <div>
               Industry : 
               <span class="font-weight-bold">
                   
                   {{ this.company.industry }}
                </span>
            </div>
            <div>
                Main-Category :
                <span class="font-weight-bold">
                   
                   {{ (this.company.subcription_type!=null)?this.company.subcription_type:"-" }}
                </span>

            </div>
            <div>
                Sub-Category : 
                <span class="font-weight-bold">
                   
                    {{ (this.company.branch!=null)?this.company.branch:"-" }}
                </span>
            </div> -->
        </div>
    </div>
</template>

<script>

export default{
props:[
    "data",
],
data:()=>({
    subscriptionHistory:null,
}),
mounted(){
    this.fetch();
},
methods:{
    fetch(){
        this.subscriptionHistory = this.data;
    },
    convertTimeZone(time){
            return this.$moment(time).format("LL");
    }
}


}
</script>