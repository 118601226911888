<template>
    <div class="pb-4">
        <div v-if="this.data != null" class="my-3">
            <div
                v-for="(item,key) in this.data"
                :key="key">
                {{ key }} :
                <span
                    class="font-weight-bold">
                    {{ item }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "data",
    ],
    data: () => ({
        users: [],
    }),
    mounted() {
    },
    methods: {
        fetch() {
        },
        checkIfCanAccessOnboarding() {
            if (this.$store.getters.getFeatures.onboarding.status == 1 || (this.$store.getters.getFeatures.onboarding.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
        checkIfCanAccessBonusOffer() {
            if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
    }


}
</script>